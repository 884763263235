import './App.css';
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RingLoader from "react-spinners/RingLoader";
import { useTheme } from "@mui/material/styles";
import { Host } from "./config";
import { Unstable_NumberInput as NumberInput, numberInputClasses } from '@mui/base';
import { grey, blue } from '@mui/material/colors';
import { styled } from '@mui/system';
import Divider from '@mui/material/Divider';
import Feedback from "./Feedback";

const Admin = ({ profile }) => {
    const theme = useTheme();
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const modalState = useState(false);
    const [modalOpen, setModalOpen] = modalState;
    const [exclusionUse, setExclusionUse] = useState(30);
    const [exclusionRequest, setRequestUse] = useState(40);
    const [changesSaved, setChangesSaved] = useState(true);
    const [saveTriggered, setSaveTriggered] = useState(false);

    if (profile == null || profile === undefined || profile.length == 0) {
        console.log("error");
        navigate("/../login", { replace: true });
    }

    const StyledInputRoot = styled('div')(
        ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;
  margin-top:0px;
  gap:0px;

  &.${numberInputClasses.focused} {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
    );

    const StyledInputElement = styled('input')(
        ({ theme }) => `
  font-size: 2rem;
  font-family: inherit;
  width:50%;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 0px 8px 12px;
  outline: 0;
`,
    );

    const StyledButton = styled('button')(
        ({ theme }) => `
  display: flex;
  font-size: 2rem;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 40px;
  height: 40px;
  font-family: system-ui, sans-serif;
  line-height: 1;
  box-sizing: border-box;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    &:hover {
      cursor: pointer;
      background: ${blue[400]};
      color: ${grey[50]};
    }

  border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    &:hover {
      cursor: pointer;
      background: ${blue[400]};
      color: ${grey[50]};
    }

  border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  }
  & .arrow {
    transform: translateY(-1px);
  }
`,
    );

    useEffect(() => {
        if (!data) {
            console.log("admin data load");
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: profile.cred
            };
            try {
                fetch(Host + '/CheckForCodes', requestOptions)
                    .then(resp => resp.json())
                    .then(x => setData(x))
                    .catch(error => {
                        console.error(error);
                        navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                    });
            }
            catch (error) {
                console.error(error);
                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
            }
        } else if (!data.isAdmin) {
            console.log("data", data);
            navigate("/../home", { replace: true });
        }
    }, [data, profile.cred, navigate]);

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: profile.cred
        };
        try {
            fetch(Host + '/GetGroupDetails', requestOptions)
                .then(resp => resp.json())
                .then(groupDetailsResponse => {
                    setExclusionUse(groupDetailsResponse.defaultLockTime);
                    setRequestUse(groupDetailsResponse.lockTimeAfterConfirmation);
                    setChangesSaved(true);
                })
                .catch(error => {
                    console.error(error);
                    navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                });
        }
        catch (error) {
            console.error(error);
            navigate("/../error", { replace: true, state: { profile: profile, err: error } });
        }
    }, [profile.cred]);

    useEffect(() => {
        if (saveTriggered && !changesSaved) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    Jwt: profile.cred,
                    DefaultLockTime: exclusionUse,
                    LockTimeAfterConfirmation: exclusionRequest
                })
            };
            try {
                fetch(Host + '/SaveGroupDetails', requestOptions)
                    .then(resp => {
                        if (resp.ok) {
                            setSaveTriggered(false);
                            setChangesSaved(true);
                            setModalOpen(true);
                        }
                        else {
                            console.log(resp);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                    });
            }
            catch (error) {
                console.error(error);
                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
            }
        }
    }, [saveTriggered, changesSaved, exclusionUse, exclusionRequest, profile.cred]);

    if (!data) {
        return (<div>
            <RingLoader color={theme.palette.primary.main} cssOverride={{ margin: "50px auto 30px auto", paddingRight: "10px" }} />
            <div>Loading...</div>
        </div >);
    } else {
        return (
            <div>
            <br />
                <Stack mx={2} spacing={3} >
                    <div>
                        <Typography variant="h3" sx={{ fontSize: '0.9rem', textAlign: "left" }}>
                            Code lockout after code generation in minutes (uses above timer if code use is confirmed):
                        </Typography>
                        <NumberInput value={exclusionUse}
                            onChange={(e, v) => { setExclusionUse(v); setChangesSaved(false); }}
                            min={0}
                            max={720}
                            slots={{
                                root: StyledInputRoot,
                                input: StyledInputElement,
                                incrementButton: StyledButton,
                                decrementButton: StyledButton,
                            }}
                            slotProps={{
                                incrementButton: {
                                    children: '▴',
                                },
                                decrementButton: {
                                    children: '▾',
                                },
                            }}
                        />
                    </div>

                    <div>
                        <Typography variant="h3" sx={{ fontSize: '0.9rem', textAlign: "left" }}>
                            Code lockout after confirmed usage in minutes:
                        </Typography>
                        <NumberInput value={exclusionRequest}
                            onChange={(e, v) => { setRequestUse(v); setChangesSaved(false); }}
                            min={0}
                            max={720}
                            slots={{
                                root: StyledInputRoot,
                                input: StyledInputElement,
                                incrementButton: StyledButton,
                                decrementButton: StyledButton,
                            }}
                            slotProps={{
                                incrementButton: {
                                    children: '▴',
                                },
                                decrementButton: {
                                    children: '▾',
                                },
                            }}
                        />
                    </div>
                    <Typography variant="h3" sx={{ fontSize: '0.8rem', textAlign: "center" }}>
                        (Settings apply to all codes in this group)
                    </Typography>
                    <Button disabled={changesSaved} onClick={()=>setSaveTriggered(true)} variant="contained">Save Changes</Button>
                    <Divider variant="middle" />
                    <Button component={Link} to="/../ManageCodes" variant="contained">Manage codes</Button>
                    <Button component={Link} to="/../ManageUsers" variant="contained">Manage users</Button>
                    <Button disabled={true} variant="contained">Stats</Button>
                </Stack>
                <Feedback openState={modalState} message="Changes saved" />
            </div>
        );
    }
}

export default Admin;

