import './App.css';

import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { Buffer } from 'buffer';
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback } from 'react';

const Login = ({ setProfile }) => {

    console.log("USING LOGIN");
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from || "/home";
    const decodeJwtResponse = (token) => JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    const handleCredentialResponse = (response) => decodeJwtResponse(response.credential);
    console.log(location.state?.from);
    console.log(from);



    useEffect(() => {
        const cookies = document.cookie.split(';');
        const userCookie = cookies.find(cookie => cookie.trim().startsWith('userInfo='));
        const userData = userCookie ? JSON.parse(userCookie.split(/=(.*)/s)[1]) : null;
        if (userData && userData.exp) {
            let currentTime = (new Date().getTime() / 1000) + 120; //add a couple of minutes to see if we need to refresh token soon...
            let remaingSeconds = userData.exp - currentTime + 518400; //518400: 6 days
            console.log("remaingSeconds: ", remaingSeconds);
            if (remaingSeconds > 0) {
                //got time on token, use this.
                setProfile(userData);
                if (from.pathname != null) {
                    console.log("x");
                    navigate(from, { replace: true });
                }
                else {
                    console.log("y");
                    navigate("/../home", { replace: true });
                }

            }
        }
    });


    return (

        //need to shift this to useGoogleLogin() hook so to be able to get a google auth code, so can perform token refresh.
        <GoogleLogin
            onSuccess={credentialResponse => {
                console.log("credentialResponse: ", credentialResponse);
                let user = handleCredentialResponse(credentialResponse);
                let d = new Date(0);
                d.setUTCSeconds(user.exp);
                user.humanExp = d.toUTCString();
                user.cred = credentialResponse.credential;
                setProfile(user);
                var now = new Date();
                var time = now.getTime();
                var expireTime = time + 1000 * 60 * 60 * 24 * 7; // 7 days
                now.setTime(expireTime);
                document.cookie = `userInfo=${JSON.stringify(user)};expires= ${now.toUTCString()};path=/`;
                console.log("Login Success", user);
                navigate(from, { replace: true });

            }}
            onError={(error) => {
                console.log('Login Failed', error);
            }}
            useOneTap
            auto_select
        />
    )

}

export default Login;
