import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RingLoader from "react-spinners/RingLoader";
import { useTheme } from "@mui/material/styles";
import { Host } from "./config";
import { useNavigate, Link } from "react-router-dom";

const ShareGroup = ({ profile }) => {

    const baseUrl = "https://coffeeqr.co.uk/joingroup?invite=";
    const [data, setData] = useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const GetInviteCode = () => {
        try {
            async function GetInviteCode() {
                console.log(profile);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: profile.cred
                };
                const response = await fetch(Host + '/Invite', requestOptions);
                const respJson = await response.json();
                console.log(respJson);
                if (respJson != null && respJson.invite != null) {
                    setData(respJson);
                }
                else {
                    setData(null);
                }
            }
            GetInviteCode();
        }
        catch (error) {
            console.error(error);
            navigate("/../error", { replace: true, state: { profile: profile, err: error } });
        }
    };

    const GetBody = () =>
        `
${profile.name} has invited you to join their group in coffeeQR, a QR code sharing service. Use the link to accept.
 
`;

    const shareDetails = () => {
        let url = baseUrl.trim() + data.invite.trim();
        let title = `Join our group in coffeeQR!
        `
        let text = GetBody();
        return { url, title, text };
    };

    const handleSharing = async () => {
        if (navigator.share) {
            try {
                if (data == null) {
                    GetInviteCode();
                    handleOpen();
                } else {
                    handleClose();
                    await navigator
                        .share(shareDetails())
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            // fallback code
            console.log("ERROR, sharing not supported on this device");
        }
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 250,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            {navigator.share ? (
                <Button variant="contained" onClick={handleSharing}>Invite someone to group</Button>
            ) : (
                <Button component={Link} to="/../invitegroup" variant="contained">Invite someone to group</Button>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {data == null ? (
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center" }} >
                                Generating code
                            </Typography>
                            <RingLoader color={theme.palette.primary.main} cssOverride={{ margin: "50px auto 30px auto", paddingRight: "10px" }} />
                            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ textAlign: "center" }}>
                                Generating secure share link...
                            </Typography>
                        </>)
                        : (
                            <div>
                                <Button variant="contained" style={{ width: "100%" }} onClick={handleSharing}>Invite!</Button>
                            </div>)
                    }
                </Box>
            </Modal>
        </>
    );
}

export default ShareGroup;