import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

const Header = ({ profile }) => {

    const [img, setImg] = useState(null);
    const [name, setName] = useState(null);

    useEffect(() => {
        if (profile != null && profile.picture != null && profile.name != null) {
            setImg(profile.picture);
            setName(profile.name);
            
        }
        else {
            setImg(null);
        }
    }, [profile]);

    return (
        <AppBar position="static">
            <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
                
                {img ?
                    <Link to="/home">
                        <div style={{width: "40px"}}>
                            <HomeIcon sx={{ fontSize: "50px", color:"white" }}  />
                        </div>
                    </Link>
                : <div></div>}
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    CoffeeQR<sup style={{ fontSize: "12px" }}> BETA</sup>
                </Typography>
                {img ?
                    <Link to="/user">
                        <img src={img} alt={name} referrerPolicy="no-referrer"
                            style={{

                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                            }}
                            />
                    </Link> : <div></div>}
            </Toolbar>
        </AppBar>
    );
}

export default Header;