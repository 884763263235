import './App.css';
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RingLoader from "react-spinners/RingLoader";
import { useTheme } from "@mui/material/styles";
import ShareGroup from './ShareGroup';
import { Host } from "./config";
import Box from '@mui/material/Box';
import { googleLogout } from '@react-oauth/google';
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TwitterShareButton,
    XIcon,
} from "react-share";

const Home = ({ profile, setProfile }) => {

    const [data, setData] = useState(null);
    const [timeString, setTimeString] = useState(null);
    const [available, setAvailable] = useState(0);
    const [usesRemain, setUsesRemain] = useState(-1)
    const theme = useTheme();
    const navigate = useNavigate();
    console.log("HOME RENDER");
    if (profile == null || profile === undefined || profile.length == 0) {
        console.log("error");
        navigate("/../login", { replace: true });
    }

    const fetchData = useCallback(async () => {
        try {
            console.log("checking for codes");
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: profile.cred
            };
            const response = await fetch(Host + '/CheckForCodes', requestOptions);

            console.log("BEEP", response);
            if (response.status === 401) {
                console.log("trigger logout");
                googleLogout();
                document.cookie = 'userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                setProfile(null);
                navigate("/", { replace: true });
            }
            const data = await response.json();
            setData(data);
        }
        catch (error) {
            console.error(error);
            navigate("/../error", { replace: true, state: { profile: profile, err: error } });
        }
    }, [profile]);

    useEffect(() => {
        console.log("fetch data effect");
        console.log(profile);
        fetchData();
    }, [profile, fetchData]);


    useEffect(() => {
        console.log("twiddle");
    }, []);

    useEffect(() => {
        if (data != null) {
            setAvailable(data.codesAvailable);
            setUsesRemain(data.codeUsesRemaining);
        }

        if (data != null && data.nextAvailableCodeAt != null) {
            let date = new Date(Date.parse(data.nextAvailableCodeAt)); //date is returned as utc.
            let current = new Date();
            let diff = date - current;
            if (diff < 0) {
                setTimeString(null);
            }
            else {
                let timestamp = diff; // Replace with your timestamp in milliseconds
                let minutes = Math.floor(timestamp / 60000);
                let seconds = ((timestamp % 60000) / 1000).toFixed(0);
                let formattedTime = `${(minutes < 10 ? "0" : "")}${minutes}:${(seconds < 10 ? "0" : "")}${seconds}`;
                if (minutes > 59) {
                    let hours = Math.floor(minutes / 60);
                    minutes = minutes % 60;
                    formattedTime = `${(hours < 10 ? "0" : "")}${hours}:${(minutes < 10 ? "0" : "")}${minutes}:${(seconds < 10 ? "0" : "")}${seconds}`;
                }

                setTimeString({ str: "Next code available in: " + formattedTime, val: date });
            }
        }
        else {
            setTimeString({ str: "No codes found - Add a code!", val: null });
        }


    }, [data]);

    useEffect(() => {
        if (timeString != null && timeString.val != null) {
            const secondsTimer = setInterval(() => {
                let date = timeString.val;
                let current = new Date();
                let diff = date - current;
                if (diff < 0) {
                    console.log("time out effect");
                    setTimeString(null);
                    clearInterval(secondsTimer);
                    fetchData();
                }
                else {
                    let timestamp = diff; // Replace with your timestamp in milliseconds
                    let minutes = Math.floor(timestamp / 60000);
                    let seconds = ((timestamp % 60000) / 1000).toFixed(0);
                    let formattedTime = `${(minutes < 10 ? "0" : "")}${minutes}:${(seconds < 10 ? "0" : "")}${seconds}`;
                    if (minutes > 59) {
                        let hours = Math.floor(minutes / 60);
                        minutes = minutes % 60;
                        formattedTime = `${(hours < 10 ? "0" : "")}${hours}:${(minutes < 10 ? "0" : "")}${minutes}:${(seconds < 10 ? "0" : "")}${seconds}`;
                    }
                    setTimeString({ str: "Next code available in: " + formattedTime, val: date });
                }
            }, 1000);
            return () => clearInterval(secondsTimer);
        }
    }, [timeString, fetchData]);

    if (!data) {
        return (<div>
            <RingLoader color={theme.palette.primary.main} cssOverride={{ margin: "50px auto 30px auto", paddingRight: "10px" }} />
            <div>Loading...</div>
        </div >);
    }

    const shareUrl = "https://coffeeQR.co.uk";
    const title = "CoffeeQR";

    return (

        <div>
            <Typography sx={{ marginTop: "10px" }} variant="h6">Hi {profile.name}!</Typography>
            <br />
            <Stack mx={2} spacing={3} sx={{ marginTop: "10px" }} >
                {(timeString != null) ?
                    <Typography variant="h3" sx={{ fontSize: '2rem' }}>
                        {timeString.str}
                    </Typography> : null
                }
                <Button disabled={timeString != null} component={Link} to="/../code" variant="contained">Get code ({available} available)</Button>
                {
                    (usesRemain > -1) ?
                        (<Typography variant="h3" sx={{ fontSize: '1rem' }}>
                            {usesRemain} uses remaining today.
                        </Typography>) : null
                }
                
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    flexDirection: "column"
                }} >
                    <Typography sx={{ marginTop: "10px" }} variant="subtitle2">Let others know about coffeeQR!</Typography>
                    <Box sx={{
                        //display: "grid",
                        //gridGap: "20px",
                        ////gridTemplateColumns: "none",
                        //gridTemplateColumns: "repeat(auto-fill, 30px)",
                        //maxWidth: "400px",
                        //margin: "0 auto"
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        marginTop:"8px"
                    }} >
                        <FacebookShareButton url={shareUrl} style={{ marginLeft: "10px", marginRight: "10px" }}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} title={title} style={{ marginLeft: "10px", marginRight: "10px" }}>
                            <XIcon size={32} round />
                        </TwitterShareButton>
                        <LinkedinShareButton url={shareUrl} title={title} style={{ marginLeft: "10px", marginRight: "10px" }}>
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                        <RedditShareButton url={shareUrl} title={title} style={{ marginLeft: "10px", marginRight: "10px" }} >
                            <RedditIcon size={32} round />
                        </RedditShareButton>
                    </Box>
                </Box>
                <Button component={Link} to="/../help" variant="contained">Help</Button>
                <ShareGroup profile={profile} />
                <Button component={Link} to="/../addcode" variant="contained">Add a new code</Button>
                {(data != null && data.isAdmin) ?
                    <Button component={Link} to="/../admin" variant="contained">Admin</Button> /* Remove user from group; Code refresh settings; Make user admin; Remove existing code; */
                    : null
                }
                <Button component={Link} to="/../contact" variant="contained">Contact Us</Button>
                <Button disabled={true} variant="contained">Leave current group</Button> {/* Warning if last remaining admin, group will be disbanded. */}

            </Stack>
        </div>

    );
}

export default Home;

