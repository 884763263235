import {
    EmailShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    EmailIcon
} from "react-share";
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import RingLoader from "react-spinners/RingLoader";
import { useTheme } from "@mui/material/styles";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { Host } from "./config";
const AddToGroup = ({ profile }) => {
    const [data, setData] = useState(null);
    const theme = useTheme();
    const baseUrl = "https://coffeeqr.co.uk/joingroup?invite=";

    const navigate = useNavigate();
    if (profile == null || profile === undefined || profile.length == 0) {
        console.log("error");
        navigate("/../login", { replace: true });
    }

    useEffect(() => {
        try {
            async function GetInviteCode() {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: profile.cred
                };
                const response = await fetch(Host + '/Invite', requestOptions);
                const respJson = await response.json();
                console.log(respJson);
                if (respJson != null && respJson.invite != null) {
                    setData(respJson);
                }
                else {
                    setData(null);
                }
            }
            GetInviteCode();
        }
        catch (error) {
            console.error(error);
            navigate("/../error", { replace: true, state: { profile: profile, err: error } });
        }
    }, [profile]);

    const GetUrl = () => {
        if (data != null && data.invite != null) {
            return baseUrl.trim() + data.invite.trim();
        }
        return "";
    }

    const GetBody = () =>
`${profile.name} has invited you to join their group in coffeeQR, a QR code sharing service.
Use the link below to accept.
`;

    if (data == null) {
        return (<div>
            <RingLoader color={theme.palette.primary.main} cssOverride={{ margin: "50px auto 30px auto", paddingRight: "10px" }} />
            <div>Generating secure share link...</div>
        </div >);
    }

    return (
        <Box disabled={data == null} >
            <EmailShareButton disabled={data == null} subject="Hey, join our coffeeQR group!" body={GetBody()} url={GetUrl()}>
                <EmailIcon size={32} round />
                </EmailShareButton>
            <WhatsappShareButton disabled={data == null} title="Hey, join our coffeeQR group!" url={GetUrl()}>
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <Button disabled={data == null} variant="contained" href={"sms:?&body=Hey, join our coffeeQR group: " + GetUrl()}>SMS</Button>
        </Box>
    );
}

export default AddToGroup;