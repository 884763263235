import logo from './logo.svg';
import './App.css';

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, useLocation } from "react-router-dom";
import Splash from './Splash';
import Login from './Login';
import User from './User';
import Home from './Home';
import Code from './Code';
import Header from './Header';
import AddNewCode from './AddNewCode';
import AddToGroup from './AddToGroup';
import JoinGroup from './JoinGroup';
import ShareGroup from './ShareGroup';
import Help from './Help';
import Admin from './Admin';
import ManageCodes from './ManageCodes';
import ManageUsers from './ManageUsers';
import Error from './Error';
import { Host } from "./config";
import Contact from './Contact';


const App = () => {
    const [profile, setProfile] = useState([]);

    useEffect(() => {

       
        const cookies = document.cookie.split(';');
        const userCookie = cookies.find(cookie => cookie.trim().startsWith('userInfo='));
        const userData = userCookie ? JSON.parse(userCookie.split(/=(.*)/s)[1]) : null;
        if (userData && userData.exp) {
            let currentTime = (new Date().getTime() / 1000) + 120; //add a couple of minutes to see if we need to refresh token soon...
            let remaingSeconds = userData.exp - currentTime + 518400; //518400: 6 days
            console.log("remaingSeconds: ", remaingSeconds);
            if (remaingSeconds > 0) {
                //got time on token, use this.
                setProfile(userData);
            }
        }
    }, []);


    const isAuthenticated = (profileVar) => {
        console.log(profileVar);
        return profileVar && profileVar.sub;
    }

    //const GetRedirect = (state) => {
    //    if (state == null) {
    //        return null;
    //    }
    //    if (state.from != null) {
    //        if (state.from.state != null) {
    //            return GetRedirect(state.from.state);
    //        }
    //        return state.from;
    //    }
    //    return null;
    //}

    const PrivateRoute = () => {
        const auth = isAuthenticated(profile); // determine if authorized, from context or however you're doing it
        const location = useLocation();
        console.log("pRoute", location);
        //let state = GetRedirect(location.state);
        //if (state != null) {

        //}
        // If authorized, return an outlet that will render child elements
        // If not, return element that will navigate to login page
        return auth ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />;
    }

    const NotLoggedInRoute = () => {
        const auth = isAuthenticated(profile); // determine if authorized, from context or however you're doing it
        const location = useLocation();
        console.log("nliRoute", location);
        if (location != null && location.state != null && location.state.from != null) {
            return !auth ? <Outlet /> : <Navigate to={location.state.from} state={{ from: location }} replace />;
        }
        // If authorized, return an outlet that will render child elements
        // If not, return element that will navigate to login page
        return !auth ? <Outlet /> : <Navigate to="/home" state={{ from: location }} replace />;
    }

    return (
        <div className="App">
            <Router>
                <Header profile={profile} />
                <Routes>
                    <Route exact path='/' element={<NotLoggedInRoute />}>
                        <Route path="/" element={<Splash setProfile={setProfile} />}> </Route>
                    </Route>
                    <Route path="/error" element={<Error profile={profile} />}> </Route>
                    <Route path="/login" element={<Login setProfile={setProfile} />}>
                    </Route>
                    <Route exact path='/user' element={<PrivateRoute />}>
                        <Route path="/user" element={<User profile={profile} setProfile={setProfile} />} />
                    </Route>
                    <Route exact path='/contact' element={<PrivateRoute />}>
                        <Route path="/contact" element={<Contact profile={profile}/>} />
                    </Route>
                    <Route exact path='/home' element={<PrivateRoute />}>
                        <Route path="/home" element={<Home profile={profile} setProfile={setProfile} />} />
                    </Route>
                    <Route exact path='/code' element={<PrivateRoute />}>
                        <Route path="/code" element={<Code profile={profile} />} />
                    </Route>
                    <Route exact path='/invitegroup' element={<PrivateRoute />}>
                        <Route path="/invitegroup" element={<AddToGroup profile={profile} />} />
                    </Route>
                    <Route exact path='/addcode' element={<PrivateRoute />}>
                        <Route path="/addcode" element={<AddNewCode profile={profile} />} />
                    </Route>
                    <Route exact path='/joingroup' element={<PrivateRoute />}>
                        <Route path="/joingroup" element={<JoinGroup profile={profile} />} />
                    </Route>
                    <Route exact path='/shareGroup' element={<PrivateRoute />}>
                        <Route path="/shareGroup" element={<ShareGroup profile={profile} />} />
                    </Route>
                    <Route exact path='/admin' element={<PrivateRoute />}>
                        <Route path="/admin" element={<Admin profile={profile} />} />
                    </Route>
                    <Route exact path='/ManageCodes' element={<PrivateRoute />}>
                        <Route path="/ManageCodes" element={<ManageCodes profile={profile} />} />
                    </Route>
                    <Route exact path='/ManageUsers' element={<PrivateRoute />}>
                        <Route path="/ManageUsers" element={<ManageUsers profile={profile} />} />
                    </Route>
                    <Route path="/Help" element={<Help />}></Route>
                </Routes>
            </Router>
        </div>
    );
}

export default App;