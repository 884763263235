import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { createMuiTheme } from '@mui/material/styles';
import { indigo, red } from '@mui/material/colors';
import { ThemeProvider } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));

const customTheme = createMuiTheme({
    palette: {
        primary: { main: red[900] },
        secondary: { main: indigo[900] },
    },
});

root.render(
    <GoogleOAuthProvider clientId="611326176950-o5rh9rafml89ohe6ib3lgvdniclatqi4.apps.googleusercontent.com">
        {/*<React.StrictMode>*/}
        <ThemeProvider theme={customTheme}>
            <App />
        </ThemeProvider>
        {/*</React.StrictMode>*/}
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
