import './App.css';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RingLoader from "react-spinners/RingLoader";
import { useTheme } from "@mui/material/styles";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { Host } from "./config";

const JoinGroup = ({ profile }) => {

    const [invite, setInvite] = useState(null);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [inviteResp, setInviteResp] = useState(null);
    const theme = useTheme();
    const [success, setSuccess] = useState(null);
    const [accepted, setAccepted] = useState(false);

    if (profile == null || profile === undefined || profile.length == 0) {
        console.log("error");
        navigate("/../login", { replace: true });
    }
    const location = useLocation();
    console.log("join", location);
    useEffect(() => {
        if (location.search != null) {

            let queryString = location.search.substring(location.search.indexOf('?') + 1);
            let params = queryString.split('&');
            let queryParams = {};
            for (var i = 0; i < params.length; i++) {
                let indexOfEqual = params[i].indexOf('=');

                queryParams[params[i].substring(0, indexOfEqual)] = params[i].substring(indexOfEqual + 1);
            }
            let inviteCode = queryParams['invite'];

            if (inviteCode == null || inviteCode.length < 10) {
                setError("Invalid URL");
            }
            else {
                setInvite(inviteCode);
            }
        }
        else {
            setError("Invalid URL");
        }
    }, [location]);

    useEffect(() => {
        if (invite != null) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Jwt: profile.cred, Invite: invite })
            };
            console.log(requestOptions);
            try {
                fetch(Host + '/DecodeInvite', requestOptions)
                    .then((response) => {
                        console.log(response);
                        if (!response.ok) {
                            setError("Error: " + response.statusText);
                            setInviteResp(null);
                        } else {
                            response.json().then(json => {
                                setError(null);
                                setInviteResp(json);
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                    });
            }
            catch (error) {
                console.error(error);
                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
            }
        }

    }, [invite]);

    const handleAccept = async () => {
        if (inviteResp != null) {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ Jwt: profile.cred, Invite: invite })
                };
                setAccepted(true);
                fetch(Host + '/AcceptInvite', requestOptions)
                    .then((response) => {
                        if (!response.ok) {
                            setError("Error: " + response.statusText);
                            setInviteResp(null);
                        } else {
                            response.text().then(text => {
                                setError(null);
                                setSuccess(text);
                            });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                    });
            } catch (error) {
                console.log(error);
                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
            }
        }
    };

    let content;
    if (error) {
        content = error;
    }
    else if (inviteResp == null) {
        content = (
            <div>
                <RingLoader color={theme.palette.primary.main} cssOverride={{ margin: "50px auto 30px auto", paddingRight: "10px" }} />
                <div>Decoding invite...</div>
            </div >
        );
    }
    else if (success) {
        content = (
            <>
                <div>{success}</div>
                <Button component={Link} to="/../home" variant="contained">Ok</Button>
            </>
        );
    }
    else if (accepted) {
        content = (
            <div>
                <RingLoader color={theme.palette.primary.main} cssOverride={{ margin: "50px auto 30px auto", paddingRight: "10px" }} />
                <div>Accepting invite...</div>
            </div >
        );
    }
    else if (inviteResp.expired) {
        content = (`Invite link has expired, please ask ${inviteResp.requestorEmail} to resend.`);
    }
    else if (inviteResp) {
        content = (<>
            <div>{`${inviteResp.requestorName} (${inviteResp.requestorEmail}) has invited you to join their coffeeQR group!`}</div>

            <Button onClick={handleAccept} variant="contained">Accept</Button>
            <Button component={Link} to="/../home" variant="contained">Decline</Button>
        </>);
    }

    //warn about leaving existing group?
    return content;
}

export default JoinGroup;

