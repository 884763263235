import './App.css';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import RingLoader from "react-spinners/RingLoader";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material/styles";
import { Host } from "./config";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Unstable_NumberInput as NumberInput, numberInputClasses } from '@mui/base';
import { grey, blue } from '@mui/material/colors';
import { styled } from '@mui/system';
import {
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from 'react-swipeable-list';
import {
    ActionContent,
    ItemColumnCentered,
} from './swipeStyled';
import 'react-swipeable-list/dist/styles.css';

import './swipe.css';

const ManageCodes = ({ profile }) => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [codeData, setCodeData] = useState(null);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [codeUses, setCodeUses] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const theme = useTheme();

    if (profile == null || profile === undefined || profile.length == 0) {
        console.log("error");
        navigate("/../login", { replace: true });
    }

    useEffect(() => {
        if (!data) {
            try {
                console.log("admin data load");
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: profile.cred
                };
                fetch(Host + '/CheckForCodes', requestOptions)
                    .then(resp => resp.json())
                    .then(x => setData(x))
                    .then(() => {
                        fetch(Host + '/ListCodes', requestOptions)
                            .then(resp => resp.json())
                            .then(cd => setCodeData(cd))
                            .catch(error => {
                                console.error(error);
                                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                            });
                    })
                    .catch(error => {
                        console.error(error);
                        navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                    });
            }
            catch (error) {
                console.error(error);
                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
            }
        } else if (!data.isAdmin) {
            console.log("data", data);
            navigate("/../home", { replace: true });
        }
    }, [data, profile.cred, navigate]);


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 250,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const DeleteIcon = () => (
        <svg
            height="100%"
            viewBox="0 0 24 24"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
        </svg >
    );


    const EditIcon = () => (
        <svg
            height="100%"
            width="100%"
            viewBox="0 -960 960 960"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m499-287 335-335-52-52-335 335 52 52Zm-261 87q-100-5-149-42T40-349q0-65 53.5-105.5T242-503q39-3 58.5-12.5T320-542q0-26-29.5-39T193-600l7-80q103 8 151.5 41.5T400-542q0 53-38.5 83T248-423q-64 5-96 23.5T120-349q0 35 28 50.5t94 18.5l-4 80Zm280 7L353-358l382-382q20-20 47.5-20t47.5 20l70 70q20 20 20 47.5T900-575L518-193Zm-159 33q-17 4-30-9t-9-30l33-159 165 165-159 33Z" />
        </svg >
    );

    const colors = {
        accepted: '#4a7b2b',
        deleted: '#bc281e',
        rejected: '#ba772b',
        waitlist: '#296690',
    };


    const trailingActions = (id) => (
        <TrailingActions style={{ width: "200px" }} >
            <SwipeAction onClick={() => {
                console.log(`edit ${id}`);
                setSelectedItem(id);
                triggerOpenEdit(true, id);
            }
            }>
                <ActionContent style={{ backgroundColor: colors.accepted }}>
                    <ItemColumnCentered>
                        <span className="icon">
                            <EditIcon />
                        </span>
                        Edit
                    </ItemColumnCentered>
                </ActionContent>
            </SwipeAction>
            <SwipeAction onClick={() => {
                console.log(`delete ${id}`);
                setSelectedItem(id);
                setOpen(true);
            }
            }>
                <ActionContent style={{ backgroundColor: colors.deleted }}>
                    <ItemColumnCentered>
                        <span className="icon">
                            <DeleteIcon />
                        </span>
                        Delete
                    </ItemColumnCentered>
                </ActionContent>
            </SwipeAction>
        </TrailingActions>
    );

    const deleteCode = () => {
        console.log("DELETE", selectedItem);
        let code = codeData.find(c => c.codeId === selectedItem);
        setCodeData(a => a.filter((i, ix) => i.codeId !== selectedItem));
        //push to db.
        if (code) {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ Jwt: profile.cred, CodeId: code.codeId, Code: code.code })
                };
                fetch(Host + '/DeleteCode', requestOptions)
                    .then((response) => {
                        console.log(response);
                        if (!response.ok) {
                            console.log("Error: " + response.statusText);
                        } else {
                            console.log("Changes saved");
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                    });
            }
            catch (error) {
                console.error(error);
                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
            }
        }
        setOpen(false);
    };

    const editCode = () => {
        console.log("Edit", selectedItem);
        console.log("new val", codeUses);
        if (codeUses > 0) {
            try {
                setCodeData(a => a.map(i => {
                    if (i.codeId === selectedItem) {
                        i.maxUses = codeUses;
                    }
                    return i;
                }));
                //push to db.
                let code = codeData.find(c => c.codeId === selectedItem);
                if (code) {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ Jwt: profile.cred, CodeId: code.codeId, Code: code.code, MaxUses: codeUses })
                    };
                    fetch(Host + '/ChangeMaxCodeUse', requestOptions)
                        .then((response) => {
                            console.log(response);
                            if (!response.ok) {
                                console.log("Error: " + response.statusText);
                            } else {
                                console.log("Changes saved");
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                        });
                }
            }
            catch (error) {
                console.error(error);
                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
            }
        }
        triggerOpenEdit(false);
    };

    const triggerOpenEdit = (shouldOpen, id) => {
        console.log("triggerOpenEdit");
        if (shouldOpen) {
            let code = codeData.find((i, ix) => i.codeId === id);
            if (code != null) {
                if (code.maxUses != codeUses) {
                    setCodeUses(code.maxUses);
                }
            }
        }
        else {
            setCodeUses(null);
        }
        setOpenEdit(shouldOpen);
    }

    const StyledInputRoot = styled('div')(
        ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;
  margin-top:0px;
  gap:0px;

  &.${numberInputClasses.focused} {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
    );

    const StyledInputElement = styled('input')(
        ({ theme }) => `
  font-size: 2rem;
  font-family: inherit;
  width:50%;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 0px 8px 12px;
  outline: 0;
`,
    );

    const StyledButton = styled('button')(
        ({ theme }) => `
  display: flex;
  font-size: 2rem;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 40px;
  height: 40px;
  font-family: system-ui, sans-serif;
  line-height: 1;
  box-sizing: border-box;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
    border-bottom: 0;
    &:hover {
      cursor: pointer;
      background: ${blue[400]};
      color: ${grey[50]};
    }

  border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid;
    &:hover {
      cursor: pointer;
      background: ${blue[400]};
      color: ${grey[50]};
    }

  border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  }
  & .arrow {
    transform: translateY(-1px);
  }
`,
    );

    const CustomNumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
        return (
            <NumberInput
                id="rawInput"
                min={0}
                max={100}
                placeholder="--"
                value={codeUses}
                onChange={(e, val) => setCodeUses(val)}
                sx={{ margin: 1 }}
                slots={{
                    root: StyledInputRoot,
                    input: StyledInputElement,
                    incrementButton: StyledButton,
                    decrementButton: StyledButton,
                }}
                slotProps={{
                    incrementButton: {
                        children: '▴',
                    },
                    decrementButton: {
                        children: '▾',
                    },
                }}
                {...props}
                ref={ref}
            />
        );
    });

    const verticalBar = (space) => {
        return {
            position: "absolute",
            width: "2px",
            top: "50%",
            height: "30px",
            marginTop: "-15px",
            backgroundColor: "#aaa",
            right: `${space}px`
        }
    }

    if (codeData == null) {
        return (<div>
            <RingLoader color={theme.palette.primary.main} cssOverride={{ margin: "50px auto 30px auto", paddingRight: "10px" }} />
            <div>Loading...</div>
        </div >);
    }

    //https://www.npmjs.com/package/react-swipeable-list#demo
    if (codeData.length < 1) {
        return (
            <div>No codes found.</div>
        );
    }
    else {
        return (
            <>
                <div style={{ margin: "20px" }} >
                    <Typography sx={{ textAlign: "left" }} variant="subtitle1">Swipe left for options</Typography>
                    <SwipeableList fullSwipe={false}

                        type={ListType.IOS}>
                        {codeData.map(i => (
                            <SwipeableListItem
                                maxSwipe={1}
                                trailingActions={trailingActions(i.codeId)}
                                sw={{ position: "relative" }}
                            >

                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    backgroundColor: "#eae9e9",
                                    padding: "6px 4px 6px 16px",
                                    margin: "1px 0px 1px 0px"
                                }} >

                                    <Typography sx={{ textAlign: "left", fontWeight: "bold" }} variant="subtitle1">{i.code}</Typography>
                                    <Typography sx={{ textAlign: "left" }} variant="subtitle1">Owner: {i.owner}</Typography>
                                    <Typography sx={{ textAlign: "left" }} variant="subtitle1">Code used {i.usesToday} of {i.maxUses} times today</Typography>
                                    <div style={verticalBar(8)} />
                                    <div style={verticalBar(12)} />
                                    <div style={verticalBar(16)} />
                                </div>

                            </SwipeableListItem>
                        ))}
                    </SwipeableList>
                </div>

                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center" }} >
                            <strong>Delete code?</strong> <br /> This cannot be undone.
                        </Typography><br />
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button variant="contained" style={{ width: "40%" }} onClick={() => setOpen(false)}>Cancel</Button>
                            <Button variant="contained" style={{ width: "40%" }} onClick={deleteCode}>Delete</Button>
                        </Box>

                    </Box>
                </Modal>

                <Modal
                    open={openEdit}
                    onClose={() => triggerOpenEdit(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center" }} >
                            <strong>Modify</strong> <br />
                        </Typography><br />
                        <Typography variant="subtitle1" style={{ textAlign: "left" }} >
                            Max code uses per day:
                        </Typography>
                        <CustomNumberInput />
                        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button variant="contained" style={{ width: "40%" }} onClick={() => triggerOpenEdit(false)}>Cancel</Button>
                            <Button variant="contained" style={{ width: "40%" }} onClick={editCode}>Confirm</Button>
                        </Box>

                    </Box>
                </Modal>
            </>

        );
    }


}

export default ManageCodes;

