import './App.css';

import React from 'react';
import { googleLogout } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";

const User = ({ profile, setProfile }) => {

    const navigate = useNavigate();

    if (profile == null || profile === undefined || profile.length == 0) {
        googleLogout();
        console.log("error");
        document.cookie = 'userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        navigate("/../login", { replace: true });
    }
    const logOut = () => {
        googleLogout();
        document.cookie = 'userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        setProfile(null);
        navigate("/", { replace: true });
    };

    return (

        <div>
            <img src={profile.picture} alt="user" />
            <h3>User Logged in</h3>
            <p>Name: {profile.name}</p>
            <p>Email Address: {profile.email}</p>
            <br />
            <button onClick={logOut}>Log out</button>
        </div>

    );
}

export default User;

