import './App.css';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import RingLoader from "react-spinners/RingLoader";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material/styles";
import { Host } from "./config";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from 'react-swipeable-list';
import {
    ActionContent,
    ItemColumnCentered,
} from './swipeStyled';
import 'react-swipeable-list/dist/styles.css';

import './swipe.css';

const ManageUsers = ({ profile }) => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [isAdmin, setIsAdmin] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const theme = useTheme();

    if (profile == null || profile === undefined || profile.length == 0) {
        console.log("error");
        navigate("/../login", { replace: true });
    }

    useEffect(() => {
        if (!data) {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: profile.cred
                };
                fetch(Host + '/CheckForCodes', requestOptions)
                    .then(resp => resp.json())
                    .then(x => setData(x))
                    .then(() => {
                        fetch(Host + '/ListUsers', requestOptions)
                            .then(resp => resp.json())
                            .then(cd => setUserData(cd))
                            .catch(error => {
                                console.error(error);
                                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                            });
                    })
                    .catch(error => {
                        console.error(error);
                        navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                    });
            }
            catch (error) {
                console.error(error);
                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
            }
        } else if (!data.isAdmin) {
            console.log("data", data);
            navigate("/../home", { replace: true });
        }
    }, [data, profile.cred, navigate]);


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 250,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const DeleteIcon = () => (
        <svg
            height="100%"
            viewBox="0 0 24 24"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
        </svg >
    );


    const EditIcon = () => (
        <svg
            height="100%"
            width="100%"
            viewBox="0 -960 960 960"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m499-287 335-335-52-52-335 335 52 52Zm-261 87q-100-5-149-42T40-349q0-65 53.5-105.5T242-503q39-3 58.5-12.5T320-542q0-26-29.5-39T193-600l7-80q103 8 151.5 41.5T400-542q0 53-38.5 83T248-423q-64 5-96 23.5T120-349q0 35 28 50.5t94 18.5l-4 80Zm280 7L353-358l382-382q20-20 47.5-20t47.5 20l70 70q20 20 20 47.5T900-575L518-193Zm-159 33q-17 4-30-9t-9-30l33-159 165 165-159 33Z" />
        </svg >
    );

    const colors = {
        accepted: '#4a7b2b',
        deleted: '#bc281e',
        rejected: '#ba772b',
        waitlist: '#296690',
    };


    const trailingActions = (id) => (
        <TrailingActions style={{ width: "200px" }} >
            <SwipeAction onClick={() => {
                console.log(`edit ${id}`);
                setSelectedItem(id);
                triggerOpenEdit(true, id);
            }
            }>
                <ActionContent style={{ backgroundColor: colors.accepted }}>
                    <ItemColumnCentered>
                        <span className="icon">
                            <EditIcon />
                        </span>
                        Edit
                    </ItemColumnCentered>
                </ActionContent>
            </SwipeAction>
            <SwipeAction onClick={() => {
                console.log(`delete ${id}`);
                setSelectedItem(id);
                setOpen(true);
            }
            }>
                <ActionContent style={{ backgroundColor: colors.deleted }}>
                    <ItemColumnCentered>
                        <span className="icon">
                            <DeleteIcon />
                        </span>
                        Delete
                    </ItemColumnCentered>
                </ActionContent>
            </SwipeAction>
        </TrailingActions>
    );

    const removeUser = () => {
        console.log("beep");
        let user = userData.find(c => c.userId === selectedItem);
        setUserData(a => a.filter((i, ix) => i.userId !== selectedItem));
        //push to db.
        if (user) {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ Jwt: profile.cred, UserId: user.userId })
                };
                fetch(Host + '/RemoveUser', requestOptions)
                    .then((response) => {
                        console.log(response);
                        if (!response.ok) {
                            console.log("Error: " + response.statusText);
                        } else {
                            console.log("Changes saved");
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                    });
            }
            catch (error) {
                console.error(error);
                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
            }
        }
        setOpen(false);
    };

    const editUser = () => {
        console.log("Edit", selectedItem);
        console.log("new val", isAdmin);


        let user = userData.find(a => a.userId === selectedItem);

        if (user && !user.providedCode && user.isAdmin !== isAdmin) {
            try {
                setUserData(a => a.map(i => {
                    if (i.userId === selectedItem) {
                        i.isAdmin = isAdmin;
                    }
                    return i;
                }));
                //push to db.

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ Jwt: profile.cred, UserId: user.userId, IsAdmin: isAdmin })
                };
                fetch(Host + '/EditUser', requestOptions)
                    .then((response) => {
                        console.log(response);
                        if (!response.ok) {
                            console.log("Error: " + response.statusText);
                        } else {
                            console.log("Changes saved");
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                    });
            }
            catch (error) {
                console.error(error);
                navigate("/../error", { replace: true, state: { profile: profile, err: error } });
            }

        }
        triggerOpenEdit(false);
    };

    const triggerOpenEdit = (shouldOpen, id) => {
        console.log("triggerOpenEdit");
        if (shouldOpen) {
            let user = userData.find((i, ix) => i.userId === id);
            if (user != null) {
                if (user.isAdmin !== isAdmin) {
                    setIsAdmin(user.isAdmin);
                }
            }
        }
        else {
            setIsAdmin(null);
        }
        setOpenEdit(shouldOpen);
    }

    const RenderDateTime = (dt) => {
        let parsed = new Date(dt);
        return parsed.toLocaleTimeString() + ", " + parsed.toLocaleDateString();
    };
    const CustomCheckboxInput = React.forwardRef(function CustomNumberInput(props, ref) {
        let providedCode = userData.some(a => a.userId === selectedItem && a.providedCode === true)
        return (
            <>
                <FormControlLabel control={
                    <Checkbox
                        checked={isAdmin}
                        onChange={e => setIsAdmin(e.target.checked)}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled={providedCode}
                    />
                } label="Is Admin" />
                {providedCode ? (
                    <>
                        <br />
                        <Typography variant="caption">Cannot remove admin of code providers</Typography>
                        <br />
                    </>) : (<br />)}
                <br />
            </>
        );
    });

    const verticalBar = (space) => {
        return {
            position: "absolute",
            width: "2px",
            top: "50%",
            height: "30px",
            marginTop: "-15px",
            backgroundColor: "#aaa",
            right: `${space}px`
        }
    }

    if (userData == null) {
        return (<div>
            <RingLoader color={theme.palette.primary.main} cssOverride={{ margin: "50px auto 30px auto", paddingRight: "10px" }} />
            <div>Loading...</div>
        </div >);
    }

    if (userData.length < 1) {
        return (
            <div>No users found.</div>
        );
    }
    else {
        return (
            <>
                <div style={{ margin: "20px" }} >
                    <Typography sx={{ textAlign: "left" }} variant="subtitle1">Swipe left for options</Typography>
                    <SwipeableList fullSwipe={false}

                        type={ListType.IOS}>
                        {userData.map(i => (
                            <SwipeableListItem sw={{ position: "relative" }}
                                maxSwipe={1}
                                trailingActions={trailingActions(i.userId)}
                            >
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    backgroundColor: "#eae9e9",
                                    padding: "6px 4px 6px 16px",
                                    margin: "1px 0px 1px 0px"
                                }} >

                                    <Typography sx={{ textAlign: "left", fontWeight: "bold" }} variant="subtitle1">{i.userName} {i.isAdmin ? "(Admin)" : ""}</Typography>
                                    <Typography sx={{ textAlign: "left" }} variant="subtitle1">Provided code: {i.providedCode ? "Yes" : "No"}</Typography>
                                    <Typography sx={{ textAlign: "left" }} variant="subtitle2">Code uses this month {i.monthlyUses}.</Typography>
                                    <Typography sx={{ textAlign: "left" }} variant="subtitle2">Last login {RenderDateTime(i.lastAccessed)}.</Typography>
                                    <div style={verticalBar(8)} />
                                    <div style={verticalBar(12)} />
                                    <div style={verticalBar(16)} />
                                </div>

                            </SwipeableListItem>
                        ))}
                    </SwipeableList>
                </div>

                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        {userData && selectedItem && userData.some(a => a.userId === selectedItem) ? (<>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center" }} >
                                <strong>Remove {userData.find(a => a.userId === selectedItem).userName} from group?</strong> <br /> This cannot be undone.
                            </Typography><br />
                            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button variant="contained" style={{ width: "40%" }} onClick={() => setOpen(false)}>Cancel</Button>
                                <Button variant="contained" style={{ width: "40%" }} onClick={removeUser}>Delete</Button>
                            </Box>
                        </>
                        ) : (<div>error</div>)}
                    </Box>
                </Modal>

                <Modal
                    open={openEdit}
                    onClose={() => triggerOpenEdit(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        {userData && selectedItem && userData.some(a => a.userId === selectedItem) ? (<>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center" }} >
                                <strong>Edit {userData.find(a => a.userId === selectedItem).userName}</strong> <br />
                            </Typography><br />
                            <CustomCheckboxInput />
                            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button variant="contained" style={{ width: "40%" }} onClick={() => triggerOpenEdit(false)}>Cancel</Button>
                                <Button variant="contained" style={{ width: "40%" }} onClick={editUser}>Confirm</Button>
                            </Box>
                        </>
                        ) : (<div>error</div>)}
                    </Box>
                </Modal>
            </>

        );
    }


}

export default ManageUsers;

