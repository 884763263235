import './App.css';
import qrcode from 'qrcode';
import React, { useEffect, useState } from 'react';

const RenderQr = ({ code }) => {

    const [canvas, setCanvas] = useState([]);
    
    useEffect(() => {
        qrcode.toDataURL(code, {
            margin: 1,
            scale: 16,
            type: "image/png", //Possible values are: image/png, image/jpeg, image/webp.
        }, function (err, c) {
            console.log(c.length);
            setCanvas(c);
        });
    }, [code]);

    


    return (
        <div style={{ marginLeft: "25%", marginRight: "25%", marginTop: "30%", marginBottom: "15%" }} >
            <img src={canvas} style={{ width: "100%" }} />
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <p style={{ fontSize: "2em", margin:0 }}>{code}</p>
            </div>
        </div>
    );
}

export default RenderQr;

