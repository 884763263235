import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import RingLoader from "react-spinners/RingLoader";
import { useTheme } from "@mui/material/styles";
import Box from '@mui/material/Box';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Host } from "./config";
const Error = ({ profile }) => {
    // state variable holds the state of the internet connection
    const { state } = useLocation();
    let err = state != null && state.err != null && state.err.message != null ? state.err.message : "Unknown";
    if (err == "Failed to fetch") {
        err = "Network error. Check your network connection.";
    }
    return (
        <Stack mx={2} spacing={3} sx={{ marginTop: "10px" }} >
            <h1>Oh No! Something went wrong...</h1>
            <Button component={Link} to="/../home" variant="contained">Take me home</Button>
            <h2>Details:</h2>
            <Typography sx={{ marginTop: "10px" }} variant="subtitle2">{err}</Typography>
        </Stack>
    )
}

export default Error;