import './App.css';
import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import invite from './images/invite.jpeg';
import use from './images/use.jpeg';
import add from './images/add.jpeg';
import { Link } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Help = () => {


    return (
        <div style={{ margin: "20px" }} >

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    FAQ's
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign: "left" }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} >What's all this then?</Typography>
                    <Typography variant="subtitle" sx={{ fontWeight: 'light' }}>CoffeeQR is the place to store all of your QR codes in one place. We manage QR usage centrally so multiple devices can utilise your QR codes effciently.</Typography>
                    <br /><br />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} >Can I, or others in my group use other apps for the QR codes?</Typography>
                    <Typography variant="subtitle" sx={{ fontWeight: 'light' }}>No. In order to track code usage and timings, all code uses must be done via coffeQR.</Typography>
                    <br /><br />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} >Do I have to say I've used the code when it's scanned?</Typography>
                    <Typography variant="subtitle" sx={{ fontWeight: 'light' }}>Yes! If you don't do this, we cannot track when a code has been used, and so we will automatically lock the code for other devices for the generation lockout time (defaults to 40 minutes). When you click the button to confirm you've used the code, the code usage lockout time comes into affect (default 30 minutes).</Typography>
                    <br /><br />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} >What's this about releaseing a code back if I haven't used it?</Typography>
                    <Typography variant="subtitle" sx={{ fontWeight: 'light' }}>If you request a code, but don't use it, you can release the code back immediately removing the lock on the code for other devices.</Typography>
                    <br /><br />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} >How do I add a QR code to my group</Typography>
                    <Typography variant="subtitle" sx={{ fontWeight: 'light' }}>On the "add new code" screen, you can either use your camera to take a picture of a QR, or if you are able to see the text associated with the qr code (typically underneath a code), you can just type it in manually.</Typography>
                    <br /><br />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} >What's all this stuff in the admin section?</Typography>
                    <Typography variant="subtitle" sx={{ fontWeight: 'light' }}>Admins can adjust default lockout timings to support a variety of differnt QR codes, They can set the max uses of a given code per day (defaults to 5), and delete codes if required. Admins can also promote other users to admins, or remove them from the group (delete)</Typography>
                    <br /><br />
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} >How do I get group admin?</Typography>
                    <Typography variant="subtitle" sx={{ fontWeight: 'light' }}>You must start your own group (by inviting others), add a QR code of your own, or be promoted to admin by an existing admin.</Typography>
                </AccordionDetails>
            </Accordion>
            
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                >
                    Basics
                </AccordionSummary>
                <Typography variant="h3" sx={{ fontSize: '1rem', textAlign: "left" }}>
                    <br /><br />
                    <b>Invite people:</b><br /> Invite your friends or colleagues to join your CoffeeQR group.
                    <img src={invite} alt="invite" style={{ width: "90%", display: "block", marginTop: "5px", marginLeft: "auto", marginRight: "auto" }} />
                    <br /><br />
                    <b>Add QR codes:</b><br /> One or more group members can add QR codes to the group.
                    <img src={add} alt="add" style={{ width: "90%", display: "block", marginTop: "5px", marginLeft: "auto", marginRight: "auto" }} />
                    <br /><br />
                    <b>Start using:</b><br /> You can immediately start using and sharing the codes.
                    <img src={use} alt="use" style={{ width: "90%", display: "block", marginTop: "5px", marginLeft: "auto", marginRight: "auto" }} />
                    <br /><br />
                    <b>Code management:</b><br /> Code usage is tracked, ensuring you don't exceed your total usage (default of 5 uses, per code, per day, configurable). <br />The codes are locked out for others after they have been used (default of 30 minutes, configurable).
                    <br /><br /><br />
                </Typography>
            </Accordion>
            
            <Button component={Link} to="../home" variant="contained">Back</Button>
            <br /><br />
        </ div >
    );
}

export default Help;

