
import React, { useState, useEffect } from 'react';
import { BrowserQRCodeReader } from '@zxing/browser';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ReplyIcon from '@mui/icons-material/Reply';
import Feedback from "./Feedback";
import { useNavigate } from "react-router-dom";
import { Host } from "./config";
const AddNewCode = ({ profile }) => {

    const navigate = useNavigate();
    if (profile == null || profile === undefined || profile.length == 0) {
        console.log("error");
        navigate("/../login", { replace: true });
    }

    const [fileInput, setFileInput] = useState(null);
    const [textInput, setTextInput] = useState(null);
    const modalState = useState(false);
    const [modalOpen, setModalOpen] = modalState;
    const [modalMessage, setModalMessage] = useState(null);

    const handleFileRead = (e) => {
        try {
            const codeReader = new BrowserQRCodeReader();
            codeReader.decodeFromImageUrl(e.target.result)
                .then((a) => { setFileInput(a.text) })
                .catch(e => {
                    console.log(e);
                    setModalMessage("No QR code found.");
                    setModalOpen(true);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const handleFileChosen = (file) => {
        try {
            console.log("handling file");
            let fileReader = new FileReader();
            fileReader.onloadend = handleFileRead;
            fileReader.readAsDataURL(file.files[0]);
        } catch (error) {
            console.log(error);
        }
        file.value = "";
    };


    useEffect(() => {
        if (fileInput) {
            if (fileInput.length < 8) {
                setModalMessage("Error: QR code appears invalid.");
                setModalOpen(true);
            }
            else {
                console.log(profile);
                process(fileInput);
            }
        }

    }, [fileInput]);

    const submit = () => {
        if (textInput == null || textInput.length < 8) {
            setModalMessage("Error: QR code needs to be more characters.");
            setModalOpen(true);
        }
        else {
            process(textInput);
        }
    }

    const process = (input) => {
        setModalMessage(null);

        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Jwt: profile.cred, Code: input })
            };
            fetch(Host + '/AddCode', requestOptions)
                .then((response) => {
                    console.log(response);
                    if (!response.ok) {
                        setModalMessage("Error: " + response.statusText);
                    } else {
                        response.text().then(text => {
                            console.log(text);
                            setModalMessage(text);
                        });
                    }
                    setFileInput(null);
                    setTextInput(null);
                })
                .catch(error => {
                    console.error(error);
                    navigate("/../error", { replace: true, state: { profile: profile, err: error } });
                });

        }
        catch (error) {
            console.error(error);
            navigate("/../error", { replace: true, state: { profile: profile, err: error } });
        }

        setModalOpen(true);
    }

    return (
        <div>
            <Box sx={{ mt: 10 }}>
                <h3 style={{ margin: 0 }}>Select a picture with your QRcode</h3>
                <h5 style={{ margin: 5 }}> (perhaps from a screenshot)</h5>
                <input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    style={{ display: 'none' }}
                    onChange={a => handleFileChosen(a.target)}
                />
                <label htmlFor="contained-button-file">
                    <Button
                        variant="contained"

                        component="span"
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload
                    </Button>
                </label>
            </Box>
            <br /><br /><br />

            <h3 style={{ margin: 0 }} >Or type your code manually here</h3>
            <h5 style={{ margin: 5 }}>(case-sensitive)</h5>
            <br />

            <div>
                <TextField
                    id="rawInput"
                    type="text"
                    placeholder="Or type your code here"
                    onChange={a => setTextInput(a.target.value)}
                    sx={{ margin: 1 }}

                />

            </div>
            <Button
                variant="contained"
                component="span"
                onClick={()=>submit()}
                startIcon={<ReplyIcon />}
            >
                Submit
            </Button>
            <Feedback openState={modalState} message={modalMessage} />
        </div>
    );
}

export default AddNewCode;