import './App.css';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import RenderQr from './RenderQr';
import RingLoader from "react-spinners/RingLoader";
import { useTheme } from "@mui/material/styles";
import { Host } from "./config";

const Code = ({ profile }) => {

    const [data, setData] = useState(0);
    const navigate = useNavigate();
    const theme = useTheme();

    if (profile == null || profile === undefined || profile.length == 0) {
        console.log("error");
        navigate("/../login", { replace: true });
    }

    useEffect(() => {
        try {
            async function GetQrCode() {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: profile.cred
                };
                const response = await fetch(Host + '/RequestCode', requestOptions);
                const respJson = await response.json();
                console.log(respJson);
                if (respJson != null && respJson.code != null) {
                    setData(respJson.code);
                }
            }
            GetQrCode();
        }
        catch (error) {
            console.error(error);
            navigate("/../error", { replace: true, state: { profile: profile, err: error } });
        }
    }, [profile]);

    const ClaimCode = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Jwt: profile.cred, Code: data })
            };
            const response = await fetch(Host + '/ClaimCode', requestOptions);
            console.log(response);
            setData(null);
        }
        catch (error) {
            console.error(error);
            navigate("/../error", { replace: true, state: { profile: profile, err: error } });
        }
        navigate("/../home");
    };

    const ReleaseCode = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Jwt: profile.cred, Code: data })
            };
            const response = await fetch(Host + '/ReleaseCode', requestOptions);
            console.log(response);
            setData(null);
        }
        catch (error) {
            console.error(error);
            navigate("/../error", { replace: true, state: { profile: profile, err: error } });
        }
        navigate("/../home");
    };

    if (data == null) {
        return (
            <div>No code available</div>
        );
    }

    if (data === 0) {
        return (<div>
            <RingLoader color={theme.palette.primary.main} cssOverride={{ margin: "50px auto 30px auto", paddingRight: "10px" }} />
            <div>Fetching code...</div>
        </div >);
    }

    return (

        <div>
            

            {data != null &&
                <RenderQr code={data} />
            }
            <br />
            <Button style={{ margin: "10px" }}  onClick={ClaimCode} variant="contained">I used this code.</Button>
            <br /><br />
            <Button style={{ margin: "10px" }}  onClick={ReleaseCode} variant="contained">Release the code back. I haven't used it.</Button>
        </div>

    );
}

export default Code;

