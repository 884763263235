import './App.css';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import invite from './images/invite.jpeg';
import use from './images/use.jpeg';
import add from './images/add.jpeg';
import { useGoogleOneTapLogin, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';

const Splash = ({ setProfile }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [cred, setCred] = useState(null);
    const from = location.state?.from || "/home";
    const decodeJwtResponse = (token) => JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    const handleProfileResp = (response) => decodeJwtResponse(response.credential);

    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
            setCred(credentialResponse);
        },
        onError: error => {
            console.log('Login Failed', error);
            setCred(null);
        },
        auto_select: true
    });
    
    useEffect(() => {
        if (cred != null) {

            console.log("credentialResponse: ", cred);
            let user = JSON.parse(Buffer.from(cred.credential.split('.')[1], 'base64').toString());
            let d = new Date(0);
            d.setUTCSeconds(user.exp);
            user.humanExp = d.toUTCString();
            user.cred = cred.credential;
            setProfile(user);
            var now = new Date();
            var time = now.getTime();
            var expireTime = time + 1000 * 60 * 60 * 24 * 7; // 7 days
            now.setTime(expireTime);
            document.cookie = `userInfo=${JSON.stringify(user)};expires= ${now.toUTCString()};path=/`;
            console.log("Login Success", user);
            navigate(from, { replace: true });
        }
    }, [cred]);

    useEffect(() => {
        const cookies = document.cookie.split(';');
        const userCookie = cookies.find(cookie => cookie.trim().startsWith('userInfo='));
        const userData = userCookie ? JSON.parse(userCookie.split(/=(.*)/s)[1]) : null;
        if (userData && userData.exp) {
            let currentTime = (new Date().getTime() / 1000) + 120; //add a couple of minutes to see if we need to refresh token soon...
            let remaingSeconds = userData.exp - currentTime + 518400; //518400: 6 days
            console.log("remaingSeconds: ", remaingSeconds);
            if (remaingSeconds > 0) {
                //got time on token, use this.
                setProfile(userData);
                if (from.pathname != null) {
                    navigate(from, { replace: true });
                }
                else {
                    navigate("/../home", { replace: true });
                }

            }
        }
    });


    return (


        <div style={{ margin: "20px" }} >
            <Typography variant="h2" sx={{ fontSize: '2rem' }}>
                Welcome to CoffeeQR
            </Typography>
            <br /><br />
            <Typography variant="h3" sx={{ fontSize: '1rem', textAlign: "left" }}>
                <b>Sign In:</b><br /> Simply sign in with your Google account.
            </Typography>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        setCred(credentialResponse);
                    }}
                    onError={() => {
                        setCred(null);
                    }}
                    theme="filled_blue"
                    shape="circle"
                />
            </div>
            <Typography variant="h3" sx={{ fontSize: '1rem', textAlign: "left" }}>
                <b>Invite people:</b><br /> Invite your friends or colleagues to join your CoffeeQR group.
                <img src={invite} alt="invite" style={{ width: "90%", display: "block", marginTop: "5px", marginLeft: "auto", marginRight: "auto" }} />
                <br /><br />
                <b>Add QR codes:</b><br /> One or more group members can add QR codes to the group.
                <img src={add} alt="add" style={{ width: "90%", display: "block", marginTop: "5px", marginLeft: "auto", marginRight: "auto" }} />
                <br /><br />
                <b>Start using:</b><br /> You can immediately start using and sharing the codes.
                <img src={use} alt="use" style={{ width: "90%", display: "block", marginTop: "5px", marginLeft: "auto", marginRight: "auto" }} />
                <br /><br />
                <b>Code management:</b><br /> Code usage is tracked, ensuring you don't exceed your total usage (default of 5 uses, per code, per day, configurable). <br />The codes are locked out for others after they have been used (default of 30 minutes, configurable).
            </Typography>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
                <GoogleLogin
                    onSuccess={credentialResponse => {
                        setCred(credentialResponse);
                    }}
                    onError={() => {
                        setCred(null);
                    }}
                    theme="filled_blue"
                    shape="circle"
                />
            </div>
        </div>


    );
}

export default Splash;

