import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React, { useState} from 'react';
import { useNavigate } from "react-router-dom";
import { Host } from "./config";
const Contact = ({ profile }) => {

    const [body, setBody] = useState(null);
    const [contact, setContact] = useState(null);
    const navigate = useNavigate();

    const save = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Jwt: profile.cred, Contact: contact, MessageBody: body })
            };
            const response = await fetch(Host + '/AddMessage', requestOptions);
            console.log(response);
        }
        catch (error) {
            console.error(error);
            navigate("/../error", { replace: true, state: { profile: profile, err: error } });
        }
        navigate("/../home");
    }

    return (
        <Stack mx={2} spacing={0} sx={{ marginTop: "10px" }} >
            <Typography variant="h3" sx={{ fontSize: '0.9rem', textAlign: "left" }}>
                Message:
            </Typography>
            <textarea
                style={{ padding: "5px" }}
                id="messagebody"
                type="text"
                rows="10"
                onChange={a => setBody(a.target.value)}
            />
            <br/>
            <Typography variant="h3" sx={{ fontSize: '0.9rem', textAlign: "left" }}>
                Contact email:
            </Typography>
            <input
                style={{ padding: "5px" }}
                id="contact"
                type="text"
                onChange={a => setContact(a.target.value)}
            />
            <br />
            <Button disabled={body == null || contact == null}
                onClick={() => save()} variant="contained">Submit</Button>
        </Stack>
    )
}

export default Contact;