import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RingLoader from "react-spinners/RingLoader";
import { useTheme } from "@mui/material/styles";

const Feedback = ({ openState, message }) => {

    const [open, setOpen] = openState;
    const handleClose = () => setOpen(false);
    const theme = useTheme();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 250,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {message == null ? (
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center" }} >
                                Processing
                            </Typography>
                            <RingLoader color={theme.palette.primary.main} cssOverride={{ margin: "50px auto 30px auto", paddingRight: "10px" }} />
                        </>)
                        : (
                            <>
                                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center" }} >
                                    {message}
                                </Typography>
                                <div>
                                    <Button variant="contained" style={{ width: "100%" }} onClick={handleClose}>Ok</Button>
                                </div>
                            </>
                        )
                    }

                </Box>
            </Modal>
        </>
    );
}

export default Feedback;